import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['header', 'mobileMenu', 'container'];
  scrollPos = 0;

  initialize() {
    this.boundHandleEvent = this.collapse.bind(this);
  }
  connect() {
    document.addEventListener("scroll", this.boundHandleEvent);
  };
  disconnect() {
    document.removeEventListener("scroll", this.boundHandleEvent);
    this.close();
  }

  collapse() {
    const el = this.headerTarget;
    if (document.body.getBoundingClientRect().top > this.scrollPos) {
      el.classList.remove("collapse")
    } else {
      if (document.body.getBoundingClientRect().top < -el.offsetHeight) {
        el.classList.add("collapse");
      }
    }
    this.scrollPos = (document.body.getBoundingClientRect()).top;
  }

  open(event) {
    event.preventDefault();
    document.body.style.overflowY = 'hidden';
    this.mobileMenuTarget.classList.add('is-open');
    return false;
  };

  close(event) {
    event?.preventDefault();
    document.body.style.overflowY = 'auto';
    this.mobileMenuTarget.classList.remove('is-open');
    return false;
  };

  clickOutside(event) {
    if(event.target == this.mobileMenuTarget) {
      event.preventDefault();
      this.close(event);
    }
  }
};