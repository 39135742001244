import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['trigger'];
  collapse = false;

  initialize() {
    const heroState = JSON.parse(window.localStorage.getItem('greeting'));
    heroState
      ? this.collapse = JSON.parse(heroState)
      : window.localStorage.setItem('greeting', false);
  }
  connect() {
    this.toggle();
  };

  toggle(event) {
    event?.preventDefault();
    this.collapse ? this.close() : this.open();
  };

  open() {
    this.element.classList.remove('collapse');
    this.triggerTarget.textContent = 'Fermer';
    window.localStorage.setItem('greeting', false);
    this.collapse = true;
  }
  close() {
    this.element.classList.add('collapse');
    this.triggerTarget.textContent = 'Ouvrir';
    window.localStorage.setItem('greeting', true);
    this.collapse = false;
  }

};