function insertGTMScript() {
  if(window.secrets.googleTagManagerId) {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+window.secrets.googleTagManagerExtraParams;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer',window.secrets.googleTagManagerId);
    document.addEventListener('turbo:load', () => {
      window.dataLayer.push({ 'event': 'turbo:load' });
    });
  }
}

export { insertGTMScript };