import Rails from "@rails/ujs";

if (!window._rails_loaded) {
  Rails.start();
} // en prod, rails-ujs est "start" automatiquement sans avoir à faire Rails.start(), en dev non...
 // donc ajout du test !window._rails_loaded pour éviter l'erreur du double start() en prod
 // lors de la maj vers rails 6, remplacer rails-ujs par turbo/frame pour pouvoir enlever rails-ujs

import * as Turbo from "@hotwired/turbo";
Turbo.start();

import { Application } from "@hotwired/stimulus";
const application = Application.start();

import { registerControllers } from 'stimulus-vite-helpers';
const controllers = import.meta.globEager('../global_tenant/javascripts/controllers/*_controller.js');
registerControllers(application, controllers);

import "../global_tenant/javascripts/application";