import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  connect() {
  }

  onClick(event) {
    event.preventDefault();
    window.open(this.element.href, "pop", "width=700px,height=400px");
    return false;
  }
}